import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';

// Importing Modal
import { LangContext, translate } from '../../config/Translator';

const Section: React.FC = () => {
  /* const modal = useRef<ModalSection>(null);

     const callModal = useMemo(
       () => () => {
         if (modal.current) modal.current.openModal();
       },
       [modal],
     );*/
  const { l } = useContext(LangContext);
  return (
    <section
      className="section bg-home home-half"
      id="home"
      data-image-src="/images/bg-home.jpg">
      <div className="bg-overlay" />
      <div className="display-table">
        <div className="display-table-cell">
          <Container>
            <Row>
              <Col
                lg={{ size: 8, offset: 2 }}
                className="col-lg-8 offset-lg-2 text-white text-center">
                <h1 className="home-title">{translate('itPartner', l)}</h1>
                <p className="pt-3 home-desc">
                  {translate('flexibleInnovative', l)}
                </p>
                {/* TODO if Video
                <p className="play-shadow mt-4">
                  <Button
                    onClick={callModal}
                    className="play-btn video-play-icon">
                    <i className="mdi mdi-play text-center" />
                  </Button>
                </p>
                */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="wave-effect wave-anim">
        <div className="waves-shape shape-one">
          <div
            className="wave wave-one"
            style={{
              backgroundImage: `url(/assets/images/wave-shape/wave1.png)`,
            }}
          />
        </div>
        <div className="waves-shape shape-two">
          <div
            className="wave wave-two"
            style={{
              backgroundImage: `url(/assets/images/wave-shape/wave2.png)`,
            }}
          />
        </div>
        <div className="waves-shape shape-three">
          <div
            className="wave wave-three"
            style={{
              backgroundImage: `url(/assets/images/wave-shape/wave3.png)`,
            }}
          />
        </div>
      </div>

      {/* Render ModalSection Component for Modal
      <ModalSection ref={modal} channel="vimeo" videoId="99025203" /> */}
    </section>
  );
};

export default Section;
