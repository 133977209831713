import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { LangContext, translate } from '../../config/Translator';

const GetStart: React.FC = () => {
  const { l } = useContext(LangContext);
  return (
    <section className="section section-lg bg-get-start">
      <div className="bg-overlay" />
      <Container>
        <Row>
          <Col lg={{ size: 8, offset: 2 }} className="text-center">
            <h1 className="get-started-title text-white">
              {translate('letsGetStarted', l)}
            </h1>
            <div className="section-title-border mt-4 bg-white" />
            <p className="section-subtitle font-secondary text-white text-center pt-4">
              {translate('getStartedCont', l)}
            </p>
            <a
              href="mailto:info@kivi.bz.it"
              className="btn btn-white waves-effect mt-3 mb-4">
              {translate('getStarted', l)}
              <i className="mdi mdi-arrow-right" />{' '}
            </a>
          </Col>
        </Row>
      </Container>
      <div className="bg-pattern-effect">
        <img src="/assets/images/bg-pattern-light.png" alt="pattern" />
      </div>
    </section>
  );
};

export default GetStart;
