import React, { useContext, useState } from 'react';
import {
  Button,
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import StickyHeader from 'react-sticky-header';
import ScrollspyNav from './scrollSpy';

import {
  LangContext,
  translate,
  TranslatorKeys,
} from '../../config/Translator';
import 'react-sticky-header/styles.css';

type NavbarProps = {
  navClass: string;
  navItems: { id: number; idnm: string; navheading: TranslatorKeys }[];
};
const NavbarPage: React.FC<NavbarProps> = ({ navItems, navClass }) => {
  const { l } = useContext(LangContext);
  const [isOpenMenu, toggleMenu] = useState(false);
  // Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
  const targetId = navItems.map((item) => item.idnm);
  return (
    <StickyHeader
      header={
        <Navbar
          expand="lg"
          fixed="top"
          className={`navbar-custom sticky sticky-dark ${navClass}`}>
          <Container>
            <NavbarBrand className="logo text-lowercase" href="/">
              kivi
            </NavbarBrand>
            <NavbarToggler
              onClick={() => {
                toggleMenu(!isOpenMenu);
              }}>
              <i className="mdi mdi-menu" />
            </NavbarToggler>

            <Collapse id="navbarCollapse" isOpen={isOpenMenu} navbar>
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration={800}
                headerBackground
                activeNavClass="active"
                className="navbar-collapse">
                <Nav navbar className="navbar-center" id="mySidenav">
                  {navItems.map((item) => (
                    <NavItem
                      key={`navItem_${item.id}`}
                      className={item.navheading === 'Home' ? 'active' : ''}>
                      <NavLink href={`#${item.idnm}`}>
                        {translate(item.navheading, l)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <div className="nav-button ms-auto">
                  <Nav navbar className="navbar-end">
                    <li>
                      <Button
                        color="none"
                        type="button"
                        className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                        onClick={() => {
                          window.open('mailto:info@kivi.bz.it', '_blank');
                        }}>
                        Contact us
                      </Button>
                    </li>
                  </Nav>
                </div>
              </ScrollspyNav>
            </Collapse>
          </Container>
        </Navbar>
      }
      stickyOffset={-100}
    />
  );
};

export default NavbarPage;
