import React, { PropsWithChildren, useEffect, useMemo } from 'react';

type ScrollSpyProps = {
  scrollTargetIds: string[];
  scrollDuration: number;
  headerBackground: boolean;
  activeNavClass: string;
  className: string;
  router?: string;
};

const ScrollspyNav: React.FC<PropsWithChildren<ScrollSpyProps>> = ({
  scrollTargetIds,
  activeNavClass,
  headerBackground,
  scrollDuration,
  className,
  children,
  router,
}) => {
  const easeInOutQuad = (
    currentTime: number,
    start: number,
    change: number,
    duration: number,
  ) => {
    currentTime /= duration / 2;
    if (currentTime < 1)
      return (change / 2) * currentTime * currentTime + start;
    currentTime--;
    return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
  };
  const [homeDefaultLink, hashIdentifier] = useMemo(() => {
    if (router && router === 'HashRouter') {
      return ['#/', '#/#'];
    }
    return ['/', '#'];
  }, [router]);

  const scrollTo = (start: number, to: number, duration: number) => {
    const change = to - start;
    let currentTime = 0;
    const increment = 10;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      window.scrollTo(0, val);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  };

  const getNavLinkElement = (sectionID: string) =>
    document.querySelector(`a[href='${hashIdentifier}${sectionID}']`);

  const getNavToSectionID = (navHref: string) =>
    navHref.includes(hashIdentifier) ? navHref.replace(hashIdentifier, '') : '';

  useEffect(() => {
    if (document.querySelector(`a[href='${homeDefaultLink}']`)) {
      const homeDefaultElems = document.querySelector(
        `a[href='${homeDefaultLink}']`,
      );
      if (homeDefaultElems)
        homeDefaultElems.addEventListener('click', (event) => {
          event.preventDefault();
          scrollTo(window.pageYOffset, 0, scrollDuration);
          window.location.hash = '';
        });
    }
    const navDivs = document.querySelector("div[data-nav='list']");
    if (navDivs)
      navDivs.querySelectorAll('a').forEach((navLink) => {
        navLink.addEventListener('click', (event) => {
          event.preventDefault();
          const sectionID = getNavToSectionID(
            navLink.getAttribute('href') ?? '',
          );
          const sectionElem = document.getElementById(sectionID);
          if (sectionID && sectionElem) {
            const scrollTargetPosition =
              sectionElem.offsetTop -
              (headerBackground ? navDivs.scrollHeight : 0);
            scrollTo(window.scrollY, scrollTargetPosition, scrollDuration);
          } else {
            scrollTo(window.scrollY, 0, scrollDuration);
          }
        });
      });

    window.addEventListener('scroll', scrollSection, true);
    return () => {
      window.removeEventListener('scroll', scrollSection, true);
    };
  }, []);

  const scrollSection = () => {
    const navDivs = document.querySelector("div[data-nav='list']");
    let scrollSectionOffsetTop = 0;
    scrollTargetIds.forEach((sectionID, index) => {
      const sectionElem = document.getElementById(sectionID);
      if (sectionElem && navDivs)
        scrollSectionOffsetTop =
          sectionElem.offsetTop - (headerBackground ? navDivs.scrollHeight : 0);

      const navLinkElem = getNavLinkElement(sectionID);

      if (sectionElem && navLinkElem) {
        if (
          window.scrollY >= scrollSectionOffsetTop &&
          window.scrollY < scrollSectionOffsetTop + sectionElem.scrollHeight
        ) {
          navLinkElem.classList.add(activeNavClass);
          navLinkElem.parentElement?.classList.add(activeNavClass); // parentNode
          clearOtherNavLinkActiveStyle(sectionID, navLinkElem);
        } else if (navLinkElem) {
          navLinkElem.classList.remove(activeNavClass);
          navLinkElem.parentElement?.classList.remove(activeNavClass); // parentNode
        }

        if (
          window.innerHeight + window.scrollY >= document.body.scrollHeight &&
          index === scrollTargetIds.length - 1
        ) {
          navLinkElem.classList.add(activeNavClass);
          navLinkElem.parentElement?.classList.add(activeNavClass); // parentNode
          clearOtherNavLinkActiveStyle(sectionID, navLinkElem);
        }
      }
    });
  };
  const clearOtherNavLinkActiveStyle = (
    excludeSectionID: string,
    elem: Element,
  ) => {
    scrollTargetIds.forEach((sectionID) => {
      if (sectionID !== excludeSectionID) {
        elem.classList.remove(activeNavClass);
        elem.parentElement?.classList.remove(activeNavClass); // parentNode
      }
    });
  };

  return (
    <div data-nav="list" className={className}>
      {children}
    </div>
  );
};

ScrollspyNav.defaultProps = {
  router: undefined,
};

export default ScrollspyNav;
