import { createContext } from 'react';

export declare type LanguageISO = 'de' | 'it' | 'en';
type Keyword = {
  [T in LanguageISO]: string;
};

const generalKeywords = {
  template: {
    de: '',
    it: '',
    en: '',
  },
  Home: {
    de: 'Home',
    it: 'Home',
    en: 'Home',
  },
  Services: {
    de: 'Leistungen',
    it: 'Servizi',
    en: 'Services',
  },
  Features: {
    de: 'Merkmale',
    it: 'Caratteristiche',
    en: 'Features',
  },
  Credentials: {
    de: 'Referenzen',
    it: 'Credenziali',
    en: 'Credentials',
  },
  CredentialsCont: {
    de: 'Viele Kunden sind bereits begeistert von Unseren Lösungen. Schauen sie Sich die Maßgeschneiderten Lösungen an!',
    it: 'Viele Kunden sind bereits begeistert von Unseren Lösungen. Schauen sie Sich die Maßgeschneiderten Lösungen an!',
    en: 'Viele Kunden sind bereits begeistert von Unseren Lösungen. Schauen sie Sich die Maßgeschneiderten Lösungen an!',
  },
  krautundruabmDesc: {
    en: 'Onlineshop für Obst und Gemüse im Eisacktal. Webseite | Mobile App | Software für Obst/Gemüse-Waage | Liefer-App',
    it: 'Onlineshop für Obst und Gemüse im Eisacktal. Webseite | Mobile App | Software für Obst/Gemüse-Waage | Liefer-App',
    de: 'Onlineshop für Obst und Gemüse im Eisacktal. Webseite | Mobile App | Software für Obst/Gemüse-Waage | Liefer-App',
  },
  gastromixDesc: {
    de: 'Onlineshop für die Gastronomie in Bozen. Website | Liefer App ',
    it: 'Onlineshop für die Gastronomie in Bozen. Website | Liefer App ',
    en: 'Onlineshop für die Gastronomie in Bozen. Website | Liefer App ',
  },
  slowvitaDesc: {
    de: 'Onlineshop für mehrere Bäckereien in Südtirol. Website | Mobile App | Software für Import/Export in div. ESP',
    it: 'Onlineshop für mehrere Bäckereien in Südtirol. Website | Mobile App | Software für Import/Export in div. ESP',
    en: 'Onlineshop für mehrere Bäckereien in Südtirol. Website | Mobile App | Software für Import/Export in div. ESP',
  },
  clavaDesc: {
    de: 'App mit Live-Resultate im Amateursportverein in Südtirol und Trentino. Website | Mobile App',
    it: 'App mit Live-Resultate im Amateursportverein in Südtirol und Trentino. Website | Mobile App',
    en: 'App mit Live-Resultate im Amateursportverein in Südtirol und Trentino. Website | Mobile App',
  },
  wegscheiderhofDesc: {
    de: 'Landingpage für den Wegscheiderhof',
    it: 'Landingpage für den Wegscheiderhof',
    en: 'Landingpage für den Wegscheiderhof',
  },
  eisensteckenDesc: {
    de: 'Handwerkersoftware für Tischlerei Eisenstecken, Feldthurns. Projekt Management | Fakturierung | Bestellung | Mitarbeiter-management',
    it: 'Handwerkersoftware für Tischlerei Eisenstecken, Feldthurns. Projekt Management | Fakturierung | Bestellung | Mitarbeiter-management',
    en: 'Handwerkersoftware für Tischlerei Eisenstecken, Feldthurns. Projekt Management | Fakturierung | Bestellung | Mitarbeiter-management',
  },
  Team: {
    de: 'Team',
    it: 'Team',
    en: 'Team',
  },
  TeamCont: {
    de: 'Alexander Klement (Kalle) & Ivan Waldboth (Ivi) haben 2019 das Start-up Kivi gegründet. Am Beginn dieses Abenteuers zählten für uns die Neugierde, der Mut und das Interesse. Wir sind Dienstleister, Partner - ein vorausschauendes Unternehmen.',
    it: 'Alexander Klement (Kalle) & Ivan Waldboth (Ivi) haben 2019 das Start-up Kivi gegründet. Am Beginn dieses Abenteuers zählten für uns die Neugierde, der Mut und das Interesse. Wir sind Dienstleister, Partner - ein vorausschauendes Unternehmen.',
    en: 'Alexander Klement (Kalle) & Ivan Waldboth (Ivi) haben 2019 das Start-up Kivi gegründet. Am Beginn dieses Abenteuers zählten für uns die Neugierde, der Mut und das Interesse. Wir sind Dienstleister, Partner - ein vorausschauendes Unternehmen.',
  },
  Blog: {
    de: 'Blog',
    it: 'Blog',
    en: 'Blog',
  },
  Contact: {
    de: 'Kontakt',
    it: 'Contatti',
    en: 'Contact',
  },
  ContactCont: {
    de: 'Ob Sie allgemeine Informationen benötigen, spezifische Fragen haben oder eine individuelle Lösung wünschen, wir möchten von Ihnen hören.',
    it: 'Se hai bisogno di informazioni generali, hai domande specifiche o desideri una soluzione personalizzata, vogliamo sentirti.',
    en: 'Whether you need general information, have specific questions or would like a customized solution, we want to hear from you.',
  },
  de: {
    de: 'Deutsch',
    it: 'Tedesco',
    en: 'German',
  },
  it: {
    de: 'Italienisch',
    it: 'Italiano',
    en: 'Italian',
  },
  en: {
    de: 'Englisch',
    it: 'Inglese',
    en: 'English',
  },
  itPartner: {
    de: 'Ihr IT-Partner aus Südtirol',
    it: 'Ihr IT-Partner aus Südtirol',
    en: 'Ihr IT-Partner aus Südtirol',
  },
  flexibleInnovative: {
    de: 'Flexibel und Innovativ',
    it: 'Flexibel und Innovativ',
    en: 'Flexibel und Innovativ',
  },
  servicesTitle: {
    de: 'Kivi Services',
    it: 'Servizi Kivi',
    en: 'Kivi Services',
  },
  servicesIntro: {
    de: 'Wir sind zwei Programmierer in Südtirol. Die seit mehreren Jahren an komplexen und zukunftsweisenden Projekten in Südtirol arbeiten. Dazu benutzen wir die neuesten Technologien und setzten diese so ein, dass wir gute Lösungen bieten können, ohne jedesmal das Rad neu zu erfinden. Keine Aufgabe ist uns zu groß, keine Anfrage zu ungewöhnlich.',
    it: 'Wir sind zwei Programmierer in Südtirol. Die seit mehreren Jahren an komplexen und zukunftsweisenden Projekten in Südtirol arbeiten. Dazu benutzen wir die neuesten Technologien und setzten diese so ein, dass wir gute Lösungen bieten können, ohne jedesmal das Rad neu zu erfinden. Keine Aufgabe ist uns zu groß, keine Anfrage zu ungewöhnlich.',
    en: 'Wir sind zwei Programmierer in Südtirol. Die seit mehreren Jahren an komplexen und zukunftsweisenden Projekten in Südtirol arbeiten. Dazu benutzen wir die neuesten Technologien und setzten diese so ein, dass wir gute Lösungen bieten können, ohne jedesmal das Rad neu zu erfinden. Keine Aufgabe ist uns zu groß, keine Anfrage zu ungewöhnlich.',
  },
  services2Title: {
    de: 'Technologien',
    it: 'Technologie',
    en: 'Technologies',
  },
  services2Intro: {
    de: 'Von C bis NEXT.js, mit unsere vielfältigen Kompetenzen finden wir genau die Richtige Lösung für Sie!',
    it: 'Von C bis NEXT.js, mit unsere vielfältigen Kompetenzen finden wir genau die Richtige Lösung für Sie!',
    en: 'Von C bis NEXT.js, mit unsere vielfältigen Kompetenzen finden wir genau die Richtige Lösung für Sie!',
  },
  webApplications: {
    de: 'Web Applikationen',
    it: 'Web Applikationen',
    en: 'Web Applikationen',
  },
  mobileApps: {
    de: 'Mobile Apps',
    it: 'Mobile Apps',
    en: 'Mobile Apps',
  },
  software: {
    de: 'Software',
    it: 'Software',
    en: 'Software',
  },
  webApplicationsDesc: {
    de: 'Wir entwickeln innovative und einzigartige Web Applikationen für Sie und Ihren Betrieb. Wir nutzen dabei die Neuesten Technologien wie React, Angular, Kubernetes, ...',
    it: 'Wir entwickeln innovative und einzigartige Web Applikationen für Sie und Ihren Betrieb. Wir nutzen dabei die Neuesten Technologien wie React, Angular, Kubernetes, ...',
    en: 'Wir entwickeln innovative und einzigartige Web Applikationen für Sie und Ihren Betrieb. Wir nutzen dabei die Neuesten Technologien wie React, Angular, Kubernetes, ...',
  },
  mobileAppsDesc: {
    de: 'In einer immer mehr von Smartphones dominierten Welt sind mobile Apps das non-plus-ultra. Wir erstellen Apps für iOS und Android mit allem Features die Sie sich vorstellen können!',
    it: 'In einer immer mehr von Smartphones dominierten Welt sind mobile Apps das non-plus-ultra. Wir erstellen Apps für iOS und Android mit allem Features die Sie sich vorstellen können!',
    en: 'In einer immer mehr von Smartphones dominierten Welt sind mobile Apps das non-plus-ultra. Wir erstellen Apps für iOS und Android mit allem Features die Sie sich vorstellen können!',
  },
  softwareDesc: {
    de: 'Software zur Verwaltung Ihrer Betriebsprozesse, Kommunikation zu Hardware oder einfache Hilfs-programme. Keine Aufgabe ist uns zu groß!',
    it: 'Software zur Verwaltung Ihrer Betriebsprozesse, Kommunikation zu Hardware oder einfache Hilfs-programme. Keine Aufgabe ist uns zu groß!',
    en: 'Software zur Verwaltung Ihrer Betriebsprozesse, Kommunikation zu Hardware oder einfache Hilfs-programme. Keine Aufgabe ist uns zu groß!',
  },
  react: {
    de: 'React.js | React Native | NEXT.js',
    it: 'React.js | React Native | NEXT.js',
    en: 'React.js | React Native | NEXT.js',
  },
  angular: {
    de: 'Angular | Electron',
    it: 'Angular | Electron',
    en: 'Angular | Electron',
  },
  nodejs: {
    de: 'Node.js | Typescript',
    it: 'Node.js | Typescript',
    en: 'Node.js | Typescript',
  },
  php: {
    de: 'PHP | HTML | CSS',
    it: 'PHP | HTML | CSS',
    en: 'PHP | HTML | CSS',
  },
  c: {
    de: 'C | C++',
    it: 'C | C++',
    en: 'C | C++',
  },
  python: {
    de: 'Python | Machine learning',
    it: 'Python | Machine learning',
    en: 'Python | Machine learning',
  },
  database: {
    de: 'Datenbanken',
    it: 'Datenbanken',
    en: 'Databases',
  },
  docker: {
    de: 'docker | kubernetes',
    it: 'docker | kubernetes',
    en: 'docker | kubernetes',
  },
  ubuntu: {
    de: 'bash | shell',
    it: 'bash | shell',
    en: 'bash | shell',
  },
  reactDesc: {
    de: 'Die neuesten Javascript Frameworks helfen uns täglich Lösungen mit unglaublicher Geschwindigkeit zu entwickeln',
    it: 'Die neuesten Javascript Frameworks helfen uns täglich Lösungen mit unglaublicher Geschwindigkeit zu entwickeln',
    en: 'Die neuesten Javascript Frameworks helfen uns täglich Lösungen mit unglaublicher Geschwindigkeit zu entwickeln',
  },
  angularDesc: {
    de: 'Plattformübergreifende Software',
    it: 'Plattformübergreifende Software',
    en: 'Plattformübergreifende Software',
  },
  nodejsDesc: {
    de: 'Sicherer und fehlerfreier code mit Typescript',
    en: 'Sicherer und fehlerfreier code mit Typescript',
    it: 'Sicherer und fehlerfreier code mit Typescript',
  },
  phpDesc: {
    de: 'Auf die Oldtimer des Webs kann man sich immer verlassen',
    it: 'Auf die Oldtimer des Webs kann man sich immer verlassen',
    en: 'Auf die Oldtimer des Webs kann man sich immer verlassen',
  },
  cDesc: {
    de: 'Hardwarenahe Programmierung für performante Lösungen',
    it: 'Hardwarenahe Programmierung für performante Lösungen',
    en: 'Hardwarenahe Programmierung für performante Lösungen',
  },
  pythonDesc: {
    de: 'Künstliche intelligenz mit Python schafft unfassbare Dinge',
    it: 'Künstliche intelligenz mit Python schafft unfassbare Dinge',
    en: 'Künstliche intelligenz mit Python schafft unfassbare Dinge',
  },
  databaseDesc: {
    de: 'Ob SQL oder NoSQL, Datenbanken sind aus unseren Alltag nicht mehr weg zu denken',
    it: 'Ob SQL oder NoSQL, Datenbanken sind aus unseren Alltag nicht mehr weg zu denken',
    en: 'Ob SQL oder NoSQL, Datenbanken sind aus unseren Alltag nicht mehr weg zu denken',
  },
  dockerDesc: {
    de: 'Klein oder groß? Skalierbare Lösungen mit Docker, Kubernetes.',
    it: 'Klein oder groß? Skalierbare Lösungen mit Docker, Kubernetes.',
    en: 'Klein oder groß? Skalierbare Lösungen mit Docker, Kubernetes.',
  },
  ubuntuDesc: {
    de: 'Wir sind Ihre Server-admins - Hoster',
    it: 'Wir sind Ihre Server-admins - Hoster',
    en: 'Wir sind Ihre Server-admins - Hoster',
  },
  aboutUsTitle: {
    de: 'Über uns',
    it: 'Über uns',
    en: 'About us',
  },
  aboutUsSubTitle: {
    de: 'Ein duo das moderne und ansprechende Online-Erlebnisse schafft',
    it: 'Ein duo das moderne und ansprechende Online-Erlebnisse schafft',
    en: 'A duo creating modern & engaging online experiences',
  },
  aboutUsText: {
    de: 'Getrennt leben wir in Feldthurns | Südtirol. Seit der Kindheit von der IT fasziniert, hat es uns nach der Schule auf die Universität Innsbruck gezogen um dort Informatik zu Studieren. Im Jahre 2019 wurde dann entschieden unsere Passion in Taten umzusetzen! Was Sie von uns erwarten können:',
    it: 'Getrennt leben wir in Feldthurns | Südtirol. Seit der Kindheit von der IT fasziniert, hat es uns nach der Schule auf die Universität Innsbruck gezogen um dort Informatik zu Studieren. Im Jahre 2019 wurde dann entschieden unsere Passion in Taten umzusetzen! Was Sie von uns erwarten können:',
    en: 'Getrennt leben wir in Feldthurns | Südtirol. Seit der Kindheit von der IT fasziniert, hat es uns nach der Schule auf die Universität Innsbruck gezogen um dort Informatik zu Studieren. Im Jahre 2019 wurde dann entschieden unsere Passion in Taten umzusetzen! Was Sie von uns erwarten können:',
  },
  aboutUs1: {
    de: 'Zuverlässig',
    it: 'Zuverlässig',
    en: 'Zuverlässig',
  },
  aboutUs2: {
    de: 'Lokal',
    it: 'Lokal',
    en: 'Lokal',
  },
  aboutUs3: {
    de: 'Flexibel',
    it: 'Flexibel',
    en: 'Flexibel',
  },
  aboutUs4: {
    de: 'Aufwand im Design',
    it: 'Aufwand im Design',
    en: 'Effort in design',
  },
  askUs: {
    de: 'Fühlen Sie sich frei zu Fragen',
    it: 'Fühlen Sie sich frei zu Fragen',
    en: 'Ask us anything',
  },
  buildYourWebsite: {
    de: 'Erstellen Sie noch heute Ihre Traum-Website',
    it: 'Costruisci oggi il sito web dei tuoi sogni',
    en: 'Build your dream website today',
  },
  buildYourWebsiteCont: {
    de: 'Mit ausgewählten Designer arbeiten wir zusammen das Internet einen Wunderschönen Ort zu machen',
    it: 'Con designer selezionati lavoriamo insieme per rendere Internet un posto bellissimo',
    en: 'We work together with selected designers to make the Internet a beautiful place',
  },
  request: {
    de: 'Anfragen',
    it: 'Anfragen',
    en: 'Request',
  },
  whatTheySaid: {
    en: "WHAT THEY'VE SAID",
    de: 'WAS SIE GESAGT HABEN',
    it: 'QUELLO CHE HANNO DETTO',
  },
  whatTheySaidCont: {
    en: 'Die Zufriedenheit unserer Kunden hat sich immer wieder mit tollem Feedback bestätigt',
    de: 'Die Zufriedenheit unserer Kunden hat sich immer wieder mit tollem Feedback bestätigt',
    it: 'Die Zufriedenheit unserer Kunden hat sich immer wieder mit tollem Feedback bestätigt',
  },
  letsGetStarted: {
    en: "Let's Get Started",
    it: 'Iniziamo',
    de: 'Lass uns anfangen',
  },
  getStarted: {
    en: 'Get Started',
    it: 'Iniziamo',
    de: 'Anfragen',
  },
  getStartedCont: {
    de: 'Frag an, wir beraten und arbeiten kooperativ und vor allem pro-aktiv.',
    it: 'Frag an, wir beraten und arbeiten kooperativ und vor allem pro-aktiv.',
    en: 'Frag an, wir beraten und arbeiten kooperativ und vor allem pro-aktiv.',
  },
  name: {
    de: 'Dein Name',
    it: 'Tuo Nome',
    en: 'Your name',
  },
  email: {
    de: 'Deine E-Mail',
    it: 'Tua E-Mail',
    en: 'Your email',
  },
  subject: {
    de: 'Betreff..',
    it: 'Sogetto..',
    en: 'Subject..',
  },
  body: {
    de: 'Nachricht..',
    it: 'Messagio..',
    en: 'Message..',
  },
  experience: {
    de: 'Erfahrung',
    it: 'Esperanza',
    en: 'Experience',
  },
  years: {
    de: 'Jahre',
    it: 'anni',
    en: 'years',
  },
  selectLang: {
    de: 'Sprache ändern',
    it: 'Cambia lingua',
    en: 'Change language',
  },
};

export declare type TranslatorKeys = keyof typeof generalKeywords;

type Keywords = {
  [T in string]: Keyword;
};
export const keywords: Keywords = {
  ...generalKeywords,
};

export function translate<
  P extends TranslatorKeys,
  T extends { [U in string]: string },
>(key: P, lang: LanguageISO, params?: T): string {
  const keyword: Keyword = keywords[key];

  if (typeof keyword === 'undefined') {
    return key;
  }

  if (params) {
    let text = keyword[lang];
    const keys = Object.keys(params);
    keys.forEach((param) => {
      text = text
        .replace(param, params[param])
        .replace(param, params[param])
        .replace(param, params[param]);
    });
    return text;
  }
  return keyword[lang];
}

export const LangContext = createContext<{
  l: LanguageISO;
  setLang: (l: LanguageISO) => void;
}>({
  l: 'de',
  setLang: () => {
    //
  },
});
