import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { createRoot } from 'react-dom/client';
import App from './App';
import register from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';

library.add(fab, fas);

const app = (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
const root = document.getElementById('root');
if (root) createRoot(root).render(app);

register();
reportWebVitals();
