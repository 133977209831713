import React, { useMemo, useState } from 'react';
import { LangContext, LanguageISO } from './config/Translator';
import Index2 from './pages/Index2/Index2';

const App: React.FC = () => {
  const [l, setLang] = useState<LanguageISO>('de');
  const langContextValue = useMemo(() => ({ l, setLang }), [l, setLang]);
  return (
    <LangContext.Provider value={langContextValue}>
      <Index2 />
    </LangContext.Provider>
  );
};

export default App;
