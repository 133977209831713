import React, { useState } from 'react';

import NavbarPage from '../../components/Navbar/Navbar_Page';
import Section from './section';
import Service from '../../components/Service/service';
import AboutUs from '../../components/AboutUs/AboutUs';
import WebsiteDesc from '../../components/WebsiteDesc/WebsiteDesc';
import CredentialsScreen from '../../components/Credentials/credentials';
import Team from '../../components/Team/Team';
import Testimonials from '../../components/Testimonials/Testimonials';
import GetStart from '../../components/GetStart/GetStart';
import Contact from '../../components/Contact/Contact';
import Social from '../../components/Social/Social';
import Footer from '../../components/Footer/footer';
import { TranslatorKeys } from '../../config/Translator';

const Index2: React.FC = () => {
  const [navItems] = useState<
    { id: number; idnm: string; navheading: TranslatorKeys }[]
  >([
    { id: 1, idnm: 'home', navheading: 'Home' },
    { id: 2, idnm: 'features', navheading: 'Features' },
    { id: 3, idnm: 'credentials', navheading: 'Credentials' },
    { id: 4, idnm: 'services', navheading: 'Services' },
    { id: 5, idnm: 'team', navheading: 'Team' },
    /*  { id: 6, idnm: 'blog', navheading: 'Blog' }, */
    { id: 7, idnm: 'contact', navheading: 'Contact' },
  ]);
  const [navClass] = useState('');

  return (
    <>
      {/* Importing Navbar */}
      <NavbarPage navItems={navItems} navClass={navClass} />

      {/* section */}
      <Section />

      {/* about us */}
      <AboutUs />

      {/* website description */}
      <WebsiteDesc />

      {/* pricing */}
      <CredentialsScreen />

      {/* services */}
      <Service sectionClass="pt-5" />

      {/* team */}
      <Team />

      {/* process  <Process />*/}

      {/* testimonial */}
      <Testimonials />

      {/* get started */}
      <GetStart />

      {/* blog  <Blog /> */}

      {/* contact */}
      <Contact />

      {/* social */}
      <Social />

      {/* footer */}
      <Footer />
    </>
  );
};

export default Index2;
