import React from 'react';
import { Col } from 'reactstrap';

export declare type Client = {
  title: string;
  subTitle: string;
  desc: string;
};

type TestimonialBoxProps = {
  clients: Client[];
};

const TestimonialsBox: React.FC<TestimonialBoxProps> = ({ clients }) => (
  <>
    {clients.map((client) => (
      <Col lg={4} key={`client-${client.title}`}>
        <div className="testimonial-box hover-effect mt-4">
          <div className="testimonial-decs">
            <p className="text-muted text-center mb-0">“{client.desc}” </p>
          </div>
          <h5 className="text-center text-uppercase pt-3">
            {client.title} -{' '}
            <span className="text-muted text-capitalize">
              {client.subTitle}
            </span>
          </h5>
        </div>
      </Col>
    ))}
  </>
);

export default TestimonialsBox;
