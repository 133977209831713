import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LangContext, translate } from '../../config/Translator';

const LangSwitcher: React.FC = () => {
  const [isSwitchToggle, setIsSwitchToggle] = useState(false);

  const { l, setLang } = useContext(LangContext);

  const toggleSwitcher = () => {
    setIsSwitchToggle(!isSwitchToggle);
  };

  return (
    <div
      id="style-switcher"
      style={{ left: isSwitchToggle ? '0px' : '-189px' }}>
      <div>
        <h3>{translate('selectLang', l)}</h3>
        <ul className="pattern">
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={() => {
                setLang('de');
                toggleSwitcher();
              }}
              to="#">
              <img src="/assets/images/germany.png" alt={translate('en', l)} />
              {translate('de', l)}
            </Link>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={() => {
                setLang('it');
                toggleSwitcher();
              }}
              to="#">
              <img src="/assets/images/italy.png" alt={translate('it', l)} />
              {translate('it', l)}
            </Link>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={() => {
                setLang('en');
                toggleSwitcher();
              }}
              to="#">
              <img src="/assets/images/english.png" alt={translate('en', l)} />{' '}
              {translate('en', l)}
            </Link>
          </li>
        </ul>
      </div>
      <div className="bottom">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          to="#"
          onClick={toggleSwitcher}
          className="settings rounded-right">
          <FontAwesomeIcon icon="language" size="2x" />
        </Link>
      </div>
    </div>
  );
};

export default LangSwitcher;
