import React, { useContext } from 'react';
import { Col, Container, FormGroup, Row } from 'reactstrap';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Import Section Title
import { faBuilding, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faMoneyBill, faPhone } from '@fortawesome/free-solid-svg-icons';
import SectionTitle from '../common/section-title';
import { LangContext, translate } from '../../config/Translator';

const Contact: React.FC = () => {
  const { l } = useContext(LangContext);
  return (
    <section className="section " id="contact">
      <Container>
        {/* section title */}
        <SectionTitle title="Contact" desc="ContactCont" />

        <Row>
          <Col lg={4}>
            <div className="mt-4 pt-4">
              <p className="mt-4">
                <span className="h5">Ivan Waldboth:</span>
                <br />
                <span className="text-muted d-block mt-2">
                  <FontAwesomeIcon icon={faBuilding} /> Am Trumbichl 16, 39040
                  Feldthurns, BZ IT <br />
                  <FontAwesomeIcon icon={faMoneyBill} /> 03080800216 <br />
                  <a href="mailto:ivan@kivi.bz.it">
                    <FontAwesomeIcon icon={faEnvelope} /> ivan@kivi.bz.it
                  </a>
                  <br />
                  <a href="tel:+393409153895">
                    <FontAwesomeIcon icon={faPhone} /> +39 340 915 3895
                  </a>
                </span>
              </p>
              <p className="mt-4">
                <span className="h5">Alexander Klement:</span>
                <br />
                <span className="text-muted d-block mt-2">
                  <FontAwesomeIcon icon={faBuilding} /> Guln 14, 39040
                  Feldthurns, BZ IT <br />
                  <FontAwesomeIcon icon={faMoneyBill} /> 03080790219
                  <br />
                  <a href="mailto:kalle@kivi.bz.it">
                    <FontAwesomeIcon icon={faEnvelope} /> kalle@kivi.bz.it
                  </a>
                  <br />
                  <a href="tel:+393409560534">
                    <FontAwesomeIcon icon={faPhone} /> +39 340 956 0534
                  </a>
                </span>
              </p>
              <p className="mt-4">
                <span className="h5">Kivi:</span>
                <br />{' '}
                <span className="text-muted d-block mt-2">
                  <a href="mailto:info@kivi.bz.it">
                    <FontAwesomeIcon icon={faEnvelope} /> info@kivi.bz.it
                  </a>
                </span>
              </p>
            </div>
          </Col>
          <Col lg={8}>
            <div className="custom-form mt-4 pt-4">
              <div id="message" />
              <AvForm name="contact-form" id="contact-form">
                <Row>
                  <Col lg={6}>
                    <FormGroup className="mt-2">
                      <AvField
                        required
                        name="name"
                        id="name"
                        type="text"
                        className="form-control"
                        placeholder={`${translate('name', l)}*`}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup className="mt-2">
                      <AvField
                        required
                        name="email"
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder={`${translate('email', l)}*`}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormGroup className="mt-2">
                      <AvField
                        required
                        name="subject"
                        type="text"
                        className="form-control"
                        id="subject"
                        placeholder={translate('subject', l)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormGroup className="mt-2">
                      <textarea
                        name="comments"
                        id="comments"
                        rows={4}
                        className="form-control"
                        placeholder={translate('body', l)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="text-end">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="submitBnt btn btn-primary"
                      value="Send Message"
                    />
                    <div id="simple-msg" />
                  </Col>
                </Row>
              </AvForm>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
