import React from 'react';
import { Container, Row } from 'reactstrap';

// Import Section Title
import SectionTitle from '../common/section-title';

// Import Pricing
import CredentialsBox, { Credentials } from './credentials-box';

const credentials: Credentials[] = [
  {
    title: 'webApplications',
    features: [
      {
        title: 'Kraut und Ruabm',
        desc: 'krautundruabmDesc',
        link: 'https://www.krautundruabm.com',
        image: '/assets/images/creds/krautundruabm.png',
        imgShadow: true,
      },
      {
        title: 'Gastromix',
        desc: 'gastromixDesc',
        link: 'https://www.gastromix.it',
        image: '/assets/images/creds/gastromix.png',
        imgShadow: true,
      },
      {
        title: 'Slowvita',
        desc: 'slowvitaDesc',
        link: 'https://www.slowvita.it',
        image: '/assets/images/creds/slowvita.png',
        imgShadow: true,
      },
      {
        title: 'Clava',
        desc: 'clavaDesc',
        link: 'https://www.clava-sports.it',
        image: '/assets/images/creds/clava.png',
        imgShadow: true,
      },
      {
        title: 'Wegscheiderhof',
        desc: 'wegscheiderhofDesc',
        link: 'https://www.wegscheiderhof.info',
        image: '/assets/images/creds/wegscheiderhof.png',
        imgShadow: true,
      },
    ],
  },
  {
    title: 'mobileApps',
    features: [
      {
        title: 'Krautundruabm',
        desc: 'krautundruabmDesc',
        link: '#',
        image: '/assets/images/creds/krautundruabm-mobile.png',
      },
      {
        title: 'Slowvita',
        desc: 'slowvitaDesc',
        link: '#',
        image: '/assets/images/creds/slowvita-mobile.png',
      },
      {
        title: 'Clava',
        desc: 'clavaDesc',
        link: '#',
        image: '/assets/images/creds/clava-mobile.png',
      },
    ],
  },
  {
    title: 'software',
    features: [
      {
        title: 'ESP Eisenstecken',
        desc: 'eisensteckenDesc',
        link: '#',
        image: '/assets/images/creds/eisenstecken.png',
        imgShadow: true,
      },
    ],
  },
];

const CredentialsScreen: React.FC = () => (
  <section className="section pt-5" id="credentials">
    <Container>
      {/* section title */}
      <SectionTitle title="Credentials" desc="CredentialsCont" />

      <Row className="mt-5">
        {/* pricing box */}
        <CredentialsBox credentials={credentials} />
      </Row>
    </Container>
  </section>
);

export default CredentialsScreen;
