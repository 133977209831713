import React from 'react';
import { Container, Row } from 'reactstrap';

// Import Section Title
import {
  faChartLine,
  faCode,
  faDatabase,
  faNetworkWired,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import {
  faAngular,
  faDocker,
  faNodeJs,
  faPhp,
  faPython,
  faReact,
  faUbuntu,
} from '@fortawesome/free-brands-svg-icons';
import SectionTitle from '../common/section-title';
import ServiceBox, { Service } from './services-box';

type ProcessProps = {
  sectionClass: string;
};

const services1: Service[] = [
  {
    icon: faNetworkWired,
    title: 'webApplications',
    desc: 'webApplicationsDesc',
  },
  {
    icon: faPhone,
    title: 'mobileApps',
    desc: 'mobileAppsDesc',
  },
  {
    icon: faChartLine,
    title: 'software',
    desc: 'softwareDesc',
  },
];
const services2: Service[] = [
  {
    icon: faReact,
    title: 'react',
    desc: 'reactDesc',
    duration: 4,
  },
  {
    icon: faAngular,
    title: 'angular',
    desc: 'angularDesc',
    duration: 3,
  },
  {
    icon: faNodeJs,
    title: 'nodejs',
    desc: 'nodejsDesc',
    duration: 5,
  },
  {
    icon: faPhp,
    title: 'php',
    desc: 'phpDesc',
    duration: 10,
  },
  {
    icon: faCode,
    title: 'c',
    desc: 'cDesc',
    duration: 10,
  },
  {
    icon: faPython,
    title: 'python',
    desc: 'pythonDesc',
    duration: 8,
  },
  {
    icon: faDatabase,
    title: 'database',
    desc: 'databaseDesc',
    duration: 10,
  },
  {
    icon: faDocker,
    title: 'docker',
    desc: 'dockerDesc',
    duration: 3,
  },
  {
    icon: faUbuntu,
    title: 'ubuntu',
    desc: 'ubuntuDesc',
    duration: 10,
  },
];

const Process: React.FC<ProcessProps> = ({ sectionClass }) => (
  <section className={`section ${sectionClass}`} id="services">
    <Container>
      {/* section title */}
      <SectionTitle title="servicesTitle" desc="servicesIntro" />

      <Row className="mt-4">
        {/* services box */}
        <ServiceBox services={services1} />
      </Row>
      <SectionTitle title="services2Title" desc="services2Intro" />

      <Row className="mt-4">
        {/* service box */}
        <ServiceBox services={services2} />
      </Row>
    </Container>
  </section>
);

export default Process;
