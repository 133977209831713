import React, { useContext } from 'react';
import { Col } from 'reactstrap';
import {
  LangContext,
  translate,
  TranslatorKeys,
} from '../../config/Translator';

type Cred = {
  title: string;
  desc: TranslatorKeys;
  link: string;
  image: string;
  imgShadow?: true;
};

export declare type Credentials = {
  title: TranslatorKeys;
  features: Cred[];
};

type CredentialsProps = { credentials: Credentials[] };

const CredentialsBox: React.FC<CredentialsProps> = ({ credentials }) => {
  const { l } = useContext(LangContext);
  return (
    <>
      {credentials.map((credential) => (
        <Col lg={4} key={`credential-${credential.title}`}>
          <div className="text-center pricing-box hover-effect">
            <h4 className="text-uppercase">{credential.title}</h4>
            <div className="pricing-border" />
            <div className="plan-features mt-4">
              {credential.features.map((feature) => (
                <a href={feature.link} key={feature.title} className="mb-4">
                  <h5>{feature.title}</h5>
                  <p className="text-muted">{translate(feature.desc, l)}</p>
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className={feature.imgShadow ? 'img-shadow' : ''}
                  />
                </a>
              ))}
              {credential.title.toUpperCase() === 'SOFTWARE' && (
                <iframe
                  src="https://api.dev.clava-sports.com/widget/salto?language=de"
                  width={300}
                  height={250}
                  title="Clava Sports"
                />
              )}
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export default CredentialsBox;
