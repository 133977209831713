import React from 'react';
import { Container, Row } from 'reactstrap';

// Import Section Title
import SectionTitle from '../common/section-title';
import TeamBox, { TeamMember } from './TeamBox';
import './custom.css';

const teams: TeamMember[] = [
  {
    image: '/assets/images/team/ivan2.png',
    title: 'Ivan Waldboth',
    desc: 'founder',
  },
  {
    image: '/assets/images/team/kalle_new.png',
    title: 'Alexander Klement',
    desc: 'founder',
  },
];

const Team: React.FC = () => (
  <section className="section pt-4" id="team">
    <Container>
      {/* section title */}
      <SectionTitle title="Team" desc="TeamCont" />

      <Row className="mt-5 justify-content-center">
        {/* team box */}
        <TeamBox teams={teams} />
      </Row>
    </Container>
  </section>
);

export default Team;
