import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { LangContext, translate } from '../../config/Translator';

const WebsiteDesc: React.FC = () => {
  const { l } = useContext(LangContext);
  return (
    <section className="section section-lg bg-web-desc">
      <div className="bg-overlay" />
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <h2 className="text-white">{translate('buildYourWebsite', l)}</h2>
            <p className="pt-3 home-desc">
              {translate('buildYourWebsiteCont', l)}
            </p>
            <a
              href="mailto:info@kivi.bz.it"
              className="btn btn-white mt-4 waves-effect waves-light mb-5">
              {translate('request', l)}
            </a>
          </Col>
        </Row>
      </Container>
      <div className="bg-pattern-effect">
        <img src="/assets/images/bg-pattern.png" alt="dorsin" />
      </div>
    </section>
  );
};

export default WebsiteDesc;
