import React, { useContext } from 'react';
import { Col } from 'reactstrap';
import {
  findIconDefinition,
  IconLookup,
} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  LangContext,
  translate,
  TranslatorKeys,
} from '../../config/Translator';

export declare type Service = {
  title: TranslatorKeys;
  desc: TranslatorKeys;
  icon: IconLookup;
  duration?: number;
};

type ServiceBoxProps = {
  services: Service[];
};

const ServiceBox: React.FC<ServiceBoxProps> = ({ services }) => {
  const { l } = useContext(LangContext);

  return (
    <>
      {services.map((service) => (
        <Col lg={4} key={`service-${service.title}`} className="mt-3">
          <div className="services-box text-center hover-effect">
            <FontAwesomeIcon
              icon={findIconDefinition(service.icon)}
              size="5x"
            />
            <h4 className="pt-3">{translate(service.title, l)}</h4>
            <p className="pt-3 text-muted">{translate(service.desc, l)}</p>
            {service.duration && (
              <h6>
                {`${translate(
                  'experience',
                  l,
                )}: ${service.duration.toString()} ${translate('years', l)}`}
              </h6>
            )}
          </div>
        </Col>
      ))}
    </>
  );
};

export default ServiceBox;
