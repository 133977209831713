import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import {
  LangContext,
  translate,
  TranslatorKeys,
} from '../../config/Translator';

type SectionTitleProps = {
  title: TranslatorKeys;
  desc: TranslatorKeys;
};

const SectionTitle: React.FC<SectionTitleProps> = ({ title, desc }) => {
  const { l } = useContext(LangContext);
  return (
    <Row>
      <Col lg={{ size: 8, offset: 2 }}>
        <h1 className="section-title text-center">{translate(title, l)}</h1>
        <div className="section-title-border mt-3" />
        <p className="section-subtitle text-muted text-center pt-4 font-secondary">
          {translate(desc, l)}
        </p>
      </Col>
    </Row>
  );
};

export default SectionTitle;
