import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Social: React.FC = () => (
  <section className="contact-social bg-light">
    <Container>
      <Row className="align-items-center">
        <Col lg={3} className="mt-4" sm={6}>
          <a href="tel:+393409153895">
            <p className="contact-title">
              <i className="pe-7s-call" /> &nbsp;+39 340 9153895
            </p>
          </a>
        </Col>
        <Col lg={3} className="mt-4" sm={6}>
          <a href="tel:+393409560534">
            <p className="contact-title">
              <i className="pe-7s-call" /> &nbsp;+39 340 9560534
            </p>
          </a>
        </Col>
        <Col lg={3} className="mt-4 text-right" sm={6}>
          <a href="mailto:info@kivi.bz.it">
            <p className="contact-title">
              <i className="pe-7s-mail-open" />
              &nbsp; info@kivi.bz.it
            </p>
          </a>
        </Col>
        <Col lg={3} sm={6}>
          <ul className="list-inline social mt-4">
            <li className="list-inline-item">
              <a
                href="https://www.facebook.com/knolperlap"
                className="social-icon">
                <i className="mdi mdi-facebook" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://twitter.com/knolperlap" className="social-icon">
                <i className="mdi mdi-twitter" />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.linkedin.com/in/ivan-waldboth-a6b46b21a/"
                className="social-icon">
                <i className="mdi mdi-linkedin" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.github.com/csaq5507" className="social-icon">
                <i className="mdi mdi-github-circle" />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Social;
