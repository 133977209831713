import React from 'react';
import { Col } from 'reactstrap';

export declare type TeamMember = {
  image: string;
  title: string;
  desc: string;
};

type TeamBoxProp = { teams: TeamMember[] };
const TeamBox: React.FC<TeamBoxProp> = ({ teams }) => (
  <>
    {teams.map((team) => (
      <Col key={team.title} lg={4} sm={4}>
        <div className="team-box text-center hover-effect">
          <div className="team-wrapper">
            <div className="team-member">
              <img
                alt={team.title}
                src={team.image}
                className="img-fluid rounded"
              />
            </div>
          </div>
          <h4 className="team-name">{team.title}</h4>
          <p className="text-uppercase team-designation">{team.desc}</p>
        </div>
      </Col>
    ))}
  </>
);

export default TeamBox;
