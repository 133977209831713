import React from 'react';
import { Container, Row } from 'reactstrap';

// Import Section Title
import SectionTitle from '../common/section-title';
import TestimonialsBox from './TestimonialsBox';

const clients = [
  {
    title: 'Tobias Dorfmann ',
    subTitle: 'Krautundruabm',
    desc: 'Mit der Plattform von Kivi ging es für uns richtig los. Unser Startup läuft jetzt auf Hochtouren. Mehr Kunden, mehr Verkäufe – einfach spitze!',
  },
  {
    title: 'Thomas Unterfrauner ',
    subTitle: 'Gastromix',
    desc: 'Dank Gastromix geht jetzt alles flotter. Mehr Bestellungen, zufriedenere Kunden.',
  },
  {
    title: 'Hannes Eisenstecken ',
    subTitle: 'Tischlerei Eisenstecken',
    desc: 'Mit unserer Verwaltungssoftware "Eibel" haben wir den Überblick. Weniger Papierkram, mehr Zeit fürs Handwerk.',
  },
];
const Testimonials: React.FC = () => (
  <section className="section" id="testi">
    <Container>
      {/* section title */}
      <SectionTitle title="whatTheySaid" desc="whatTheySaidCont" />

      <Row className="mt-5">
        <TestimonialsBox clients={clients} />
      </Row>
    </Container>
  </section>
);

export default Testimonials;
