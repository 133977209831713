import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { LangContext, translate } from '../../config/Translator';

const AboutUs: React.FC = () => {
  const { l } = useContext(LangContext);
  return (
    <section className="section bg-light" id="features">
      <Container>
        <Row className="vertical-content">
          <Col lg={5}>
            <div className="features-box">
              <h3>{translate('aboutUsTitle', l)}</h3>
              <h6>{translate('aboutUsSubTitle', l)}</h6>
              <p className="text-muted web-desc">
                {translate('aboutUsText', l)}
              </p>
              <ul className="text-muted list-unstyled mt-4 features-item-list">
                <li className="">{translate('aboutUs1', l)}</li>
                <li className="">{translate('aboutUs2', l)}</li>
                <li className="">{translate('aboutUs3', l)}</li>
                <li className="">{translate('aboutUs4', l)}</li>
              </ul>
              <a
                href="mailto:info@kivi.bz.it"
                className="btn btn-primary mt-4 waves-effect waves-light">
                {translate('askUs', l)} <i className="mdi mdi-arrow-right" />
              </a>
            </div>
          </Col>
          <Col lg={7}>
            <div className="features-img features-right text-right">
              <img
                src="/assets/images/Logo-Kivi-2021.png"
                alt="macbook"
                className="img-fluid"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
