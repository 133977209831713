import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const FooterLinks: React.FC = () => (
  <div className="footer-alt">
    <Container>
      <Row>
        <Col lg={12}>
          <div className="float-start pull-none">
            <p className="copy-rights text-muted">
              2022 © <span className="kivi">kivi</span>
            </p>
          </div>
          <div className="clearfix" />
        </Col>
      </Row>
    </Container>
  </div>
);

export default FooterLinks;
